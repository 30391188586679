import {
  GridColumnVisibilityModel,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import {
  DEFAULT_TNS_COL_SIZES,
  DEFAULT_TNS_COL_VISIBILITY,
  DEFAULT_TNS_FIELDS,
  OptionsFeedDataGridTab,
  OptionsFeedFilterTab,
} from 'config/optionsFeed';

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Equity, Scanner } from 'types';
import {
  Filter,
  FilterConfig,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
} from 'types/optionsFeed';
const { persistAtom } = recoilPersist();

export const tnsFlowLiveState = atom<boolean>({
  key: 'tns-flowLiveState',
  default: true,
});

export const tnsActiveWatchlistsIdsState = atom<number[]>({
  key: 'tns-activeWatchlistIds',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const tnsActiveScannersState = atom<Scanner[] | undefined>({
  key: 'tns-activeScannersState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const tnsEquityScannersDataState = atom<Equity[]>({
  key: 'tns-equityScannersDataState',
  default: [],
});

export const tnsNewFilterState = atom<Filter[]>({
  key: 'tns-newFilterState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const tnsSavedFiltersState = atom<FilterConfig[]>({
  key: 'tns-savedFiltersState',
  default: [],
});

export const tnsActiveCustomFilterState = atom<FilterConfig | undefined>({
  key: 'tns-activeCustomFilterState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const tnsColumnsVisibilityState = atom<GridColumnVisibilityModel>({
  key: 'tns-tnsColumnsVisibilityState',
  default: DEFAULT_TNS_COL_VISIBILITY,
  effects_UNSTABLE: [persistAtom],
});

export const tnsColumnsSortModelState = atom<GridSortModel>({
  key: 'tns-tnsColumnsSortModelState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const tnsColumnOrderState = atom<OptionsFeedColumnKey[]>({
  key: 'tns-tnsColumnOrderState',
  default: DEFAULT_TNS_FIELDS,
  effects_UNSTABLE: [persistAtom],
});

export const tnsColumnSizesState = atom<OptionsFeedColumnSizes>({
  key: 'tns-tnsColumnSizesState',
  default: DEFAULT_TNS_COL_SIZES,
  effects_UNSTABLE: [persistAtom],
});

export const tnsHistDataState = atom<RawOptionFeedData[]>({
  key: 'tns-tnsHistDataState',
  default: [],
});

export const tnsFilterPanelContainerOpenState = atom<boolean>({
  key: 'tns-tnsFilterPanelContainerOpenState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const tnsFilterPanelContainerActiveTab = atom<OptionsFeedFilterTab>({
  key: 'tns-tnsFilterPanelContainerActiveTab',
  default: OptionsFeedFilterTab.NewFilter,
  effects_UNSTABLE: [persistAtom],
});

export const tnsDailyOverviewExpandedState = atom<boolean>({
  key: 'tns-dailyOverviewExpanded',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const tnsFlowSummaryExpandedState = atom<boolean>({
  key: 'tns-flowSummaryExpanded',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const tnsDataGridActiveTabState = atom<OptionsFeedDataGridTab>({
  key: 'tns-tnsDataGridActiveTab',
  default: OptionsFeedDataGridTab.FlowData,
  effects_UNSTABLE: [persistAtom],
});
