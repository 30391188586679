import React, { useState } from 'react';
import {
  Button,
  List,
  ListItem,
  Stack,
  Typography,
  Collapse,
  Box,
} from '@mui/material';
import { TopNameItem } from 'types/optionsFeed';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface TopNamesListProps {
  items: TopNameItem[];
  nameHeader?: string;
  valueHeader?: string;
}

const ListHeader: React.FC<{ nameHeader: string; valueHeader: string }> = ({
  nameHeader,
  valueHeader,
}) => (
  <ListItem disableGutters sx={{ width: '100%', mb: 1 }}>
    <Stack
      direction="row"
      sx={{
        gap: 2,
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="body2"
        sx={{ flexGrow: 1, fontWeight: 'bold', color: 'text.secondary' }}
      >
        {nameHeader}
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontWeight: 'bold', color: 'text.secondary' }}
      >
        {valueHeader}
      </Typography>
    </Stack>
  </ListItem>
);

const TopNamesList: React.FC<TopNamesListProps> = ({
  items,
  nameHeader = 'Ticker',
  valueHeader = 'Value',
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ListHeader nameHeader={nameHeader} valueHeader={valueHeader} />
      <List disablePadding sx={{ '& .MuiListItem-root': { py: '2px' } }}>
        {items.slice(0, 5).map((item: TopNameItem) => (
          <TopNameListItem key={item.name} item={item} />
        ))}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {items.slice(5).map((item: TopNameItem) => (
            <TopNameListItem key={item.name} item={item} />
          ))}
        </Collapse>
      </List>
      {items.length > 5 && (
        <Button
          size="small"
          onClick={toggleExpanded}
          startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{
            mt: 1,
            textTransform: 'none',
            width: '100%',

            fontSize: { xs: 12, md: 14 },
          }}
        >
          {expanded ? 'See Less' : 'See More'}
        </Button>
      )}
    </Box>
  );
};

interface TopNameItemProps {
  item: TopNameItem;
}

const TopNameListItem: React.FC<TopNameItemProps> = ({ item }) => {
  const { name, value, icon } = item;
  return (
    <ListItem disableGutters sx={{ width: '100%', minWidth: 120 }}>
      <Stack
        direction="row"
        sx={{
          gap: 2,
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        {icon}
        <Typography variant="body2" sx={{ flexGrow: 1, color: 'text.primary' }}>
          {name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {typeof value === 'string'
            ? value
            : React.isValidElement(value)
            ? value
            : null}
        </Typography>
      </Stack>
    </ListItem>
  );
};

export default TopNamesList;
