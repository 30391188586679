import { RecoilState } from 'recoil';

export const OPTION_FEED_IDX = {
  SYM: 0,
  TIME: 1,
  DELTA: 2,
  GAMMA: 3,
  VEGA: 4,
  STOCK_PRICE: 5,
  TNS_INDEX: 6,
  EXPIRATION: 7,
  STRIKE: 8,
  SIZE: 9,
  FLAG: 10,
  EXCHANGE_SALE_CONDITIONS: 11,
  PRICE: 12,
  BID: 13,
  ASK: 14,
  IVOL: 15,
};

// history api
// [
//   underlying, //  0: string
//   timestamp,  //  1: 64-bit integer (UTC milliseconds)
//   delta,      //  2: double - Delta HIRO value
//   gamma,      //  3: double - Gamma HIRO value
//   vega,       //  4: double - Vega HIRO value
//   stockPrice, //  5: double - underlying stock price at the time the transaction took place
//   sequence,   //  6: 64-bit integer
//   expiry,     //  7: 64-bit integer (UTC milliseconds)
//   strike,     //  8: float - strike price of the option
//   flags,      //  9: unsigned 32-bit integer
//   instrument, // 10: string - original option instrument namee
// ]

export enum OptionsFeedColumnKey {
  Time = 'time',
  Sym = 'sym',
  Side = 'side', // dynamically generated value
  Aggressor = 'aggressor',
  CP = 'cp',
  Strike = 'strike',
  Expiration = 'expiration',
  Size = 'size',
  Premium = 'premium', // dynamically generated value
  StockPrice = 'stockPrice',
  Price = 'price',
  Flags = 'flags',
  Bid = 'bid',
  Ask = 'ask',
  SaleType = 'saleType', // dynamically generated value
  IVol = 'iVol',
  Delta = 'delta',
  Gamma = 'gamma',
  Vega = 'vega',
  TnsIndex = 'tnsIndex',
  ExchangeSaleCondition = 'exchangeSaleCondition',
  Volume = 'volume',
  OI = 'oi',
  IsBlock = 'is_block',
  IsCross = 'is_cross',
  IsSweep = 'is_sweep',
  IsSpread = 'is_spread',
}

export interface RawOptionFeedData {
  id: string;
  [OptionsFeedColumnKey.Sym]: string;
  [OptionsFeedColumnKey.Time]: bigint;
  [OptionsFeedColumnKey.Delta]: number;
  [OptionsFeedColumnKey.Gamma]: number;
  [OptionsFeedColumnKey.Vega]: number;
  [OptionsFeedColumnKey.StockPrice]: number;
  [OptionsFeedColumnKey.TnsIndex]: bigint;
  [OptionsFeedColumnKey.Expiration]: bigint;
  [OptionsFeedColumnKey.Strike]: number;
  [OptionsFeedColumnKey.Size]: number;
  [OptionsFeedColumnKey.Flags]: OptionFlag;
  [OptionsFeedColumnKey.ExchangeSaleCondition]: string;
  [OptionsFeedColumnKey.Price]: number;
  [OptionsFeedColumnKey.Bid]: number;
  [OptionsFeedColumnKey.Ask]: number;
  [OptionsFeedColumnKey.IVol]: number;
  [OptionsFeedColumnKey.Aggressor]: Aggressor;
  [OptionsFeedColumnKey.CP]: OptionType;
  [OptionsFeedColumnKey.Volume]: number;
  [OptionsFeedColumnKey.OI]: number;
  // dynamic
  [OptionsFeedColumnKey.Premium]: number;
  [OptionsFeedColumnKey.Side]: OptionTradeSide;
  [OptionsFeedColumnKey.SaleType]?: OptionSaleType;
  [OptionsFeedColumnKey.IsBlock]?: boolean;
  [OptionsFeedColumnKey.IsSpread]?: boolean;
  [OptionsFeedColumnKey.IsSweep]?: boolean;
  [OptionsFeedColumnKey.IsCross]?: boolean;
}

export type OptionsFeedColumnVisibility = Record<OptionsFeedColumnKey, boolean>;

export type OptionsFeedColumnSizes = Record<OptionsFeedColumnKey, number>;

export enum OptionTransactionType {
  NEW = 'n',
  CORRECTION = 'r',
  CANCEL = 'c',
}

export enum OptionType {
  PUT = 'p',
  CALL = 'c',
}

export const OptionTypeLabels: Record<OptionType, string> = {
  [OptionType.PUT]: 'Put',
  [OptionType.CALL]: 'Call',
};

export enum Aggressor {
  BUY = 'buy',
  SELL = 'sell',
  UNDETERMINED = 'n/a',
}

export const AggressorLabels: Record<Aggressor, string> = {
  [Aggressor.BUY]: 'Buy',
  [Aggressor.SELL]: 'Sell',
  [Aggressor.UNDETERMINED]: 'Undetermined',
};

export enum OptionSaleType {
  BLOCK = 'block',
  SPREAD = 'spread',
  SWEEP = 'sweep',
  CROSS = 'cross',
}

export const OptionSaleTypeLabels: Record<OptionSaleType, string> = {
  [OptionSaleType.BLOCK]: 'Block',
  [OptionSaleType.SPREAD]: 'Spread',
  [OptionSaleType.SWEEP]: 'Sweep',
  [OptionSaleType.CROSS]: 'Cross',
};

export enum OptionTradeSide {
  BB = 'bb',
  B = 'b',
  M = 'm',
  A = 'a',
  AA = 'aa',
}

export const OptionTradeSideLabels: Record<OptionTradeSide, string> = {
  [OptionTradeSide.BB]: 'Below Bid',
  [OptionTradeSide.B]: 'Bid',
  [OptionTradeSide.M]: 'Mid',
  [OptionTradeSide.A]: 'Ask',
  [OptionTradeSide.AA]: 'Above Ask',
};

export interface OptionFlag {
  type: OptionType;
  transactionType: OptionTransactionType;
  sideType: Aggressor;
  isNextExp: boolean;
  isRetail: boolean;
  isBlock: boolean;
  isSpread: boolean;
  isSweep: boolean;
  isCross: boolean;
}

// [
//     string, //  0: underlying
//     BigInt, //  1: time
//     number, //  2: delta_notional
//     number, //  3: gamma_notional
//     number, //  4: vega_notional
//     number, //  5: stock_price
//     number, //  6: tns_index
//     BigInt, //  7: expiry
//     number, //  8: strike
//     number, //  9: size
//     number, // 10: flags
//     string, // 11: exchange sale conditions
//     number, // 12: price
//     number, // 13: bid
//     number, // 14: ask
//     number, // 15: ivol
//   ];

// enum for the operators
export enum FilterOperator {
  LessThan = '<',
  LessThanOrEqual = '<=',
  Equal = '=',
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  NotEqual = '!=',
  IsAnyOf = 'isAnyOf',
}

// Type mapping for value based on operator
export type FilterValueMap = {
  [FilterOperator.LessThan]: number | bigint;
  [FilterOperator.LessThanOrEqual]: number | bigint;
  [FilterOperator.Equal]: number | string | boolean;
  [FilterOperator.GreaterThan]: number | bigint;
  [FilterOperator.GreaterThanOrEqual]: number | bigint;
  [FilterOperator.NotEqual]: number | string | boolean;
  [FilterOperator.IsAnyOf]: string[] | number[];
};

export interface FilterOption {
  value: string | boolean;
  label: string;
}

export interface ConjunctionFilterOption {
  title: string;
  options: FilterOption[];
}

export enum ConjunctionOp {
  And = 'and',
  Or = 'or',
}

export interface FilterItem<FO extends FilterOperator = FilterOperator> {
  /**
   * Must be unique.
   * Useful when the model contains several items.
   */
  id: string;
  /**
   * The column from which we want to filter the rows.
   */
  field: string;

  /**
   * The name of the operator we want to apply.
   */
  operator: FilterOperator;

  /**
   * The filtering value.
   * The type of the value depends on the operator.
   */
  value: FilterValueMap[FO];
}

export interface Conjunction {
  id: string;
  op: ConjunctionOp;
  filters: Filter[];
}

export type Filter = Conjunction | FilterItem;

export interface FilterConfig {
  id: string;
  name: string;
  value: Filter[];
  noSym: boolean;
  viewOnly?: boolean;
}

export type FilterPanelProps = {
  openState: RecoilState<boolean>;
  panelView?: 'default' | 'modal';
  noSym?: boolean;
  currentSym?: string;
};

export type FilterValueFormat = 'currency' | 'percentage' | 'number';
export type TransactionSentiment = 'bullish' | 'bearish' | 'neutral';

export interface TopNameItem {
  icon?: JSX.Element;
  name: string;
  value: string | JSX.Element;
}
