import { Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import {
  GridColDef,
  GridColumnHeaderParams,
  gridDateComparator,
  gridNumberComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from '@spotgamma/x-data-grid-premium';
import { useRecoilValue } from 'recoil';
import {
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  timezoneState,
} from 'states';
import {
  dayjs,
  formatAsCompactNumber,
  formatAsCurrency,
  formatAsPercentage,
  getDateFormatted,
  isOutsideTradingHours,
  nullsToEndComparator,
  valOrNa,
} from 'util/shared';
import {
  Aggressor,
  AggressorLabels,
  OptionSaleType,
  OptionsFeedColumnKey,
  OptionTradeSide,
  OptionTradeSideLabels,
  OptionType,
  OptionTypeLabels,
  TransactionSentiment,
} from 'types/optionsFeed';
import { getTransactionSentiment } from 'util/optionsFeed';
import { SGTooltip } from 'components/core';
import { Earnings } from 'types';
import { red } from '@mui/material/colors';

interface UseOptionsFeedColumnsProps {
  disabledColumnFilters?: OptionsFeedColumnKey[];
  blurredRowIds?: string[];
  earningsList: Earnings[];
}

export const useOptionsFeedColumns = ({
  disabledColumnFilters,
  blurredRowIds,
  earningsList,
}: UseOptionsFeedColumnsProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const currentTimezone = useRecoilValue(timezoneState);

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  const defaultHeaderTitleStyles = {
    fontSize: isMobile ? 12 : 14,
    color: theme.palette.sgGreen,
    textTransform: 'capitalize',
    textAlign: 'right',
    whiteSpace: 'normal',
    lineHeight: 'normal',
  };

  const getColHeaderStyles = useCallback(
    (_params: GridColumnHeaderParams) => ({
      ...defaultHeaderTitleStyles,
      color: theme.palette.primary.main,
    }),
    [defaultHeaderTitleStyles, theme.palette.equityHubColumns],
  );

  const getBlurredStyles = (params: GridRenderCellParams) =>
    blurredRowIds?.includes(params.id as string) ? { filter: 'blur(3px)' } : {};

  const getTimeFormatted = (date: dayjs.Dayjs, currentTimezone: string) =>
    date.tz(currentTimezone).format('HH:mm:ss');

  const columns: GridColDef[] = [
    {
      headerName: 'Time',
      field: OptionsFeedColumnKey.Time,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Time),
      minWidth: 110,
      type: 'dateTime',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: bigint) => {
        return value ? dayjs.utc(parseInt(value.toString())).toDate() : null;
      },
      valueFormatter: (params: { value: Date }) => {
        return valOrNa(
          params?.value != null &&
            getTimeFormatted(dayjs(params.value).utc(), currentTimezone),
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = dayjs(params?.value).utc();
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(date != null && getTimeFormatted(date, currentTimezone))}{' '}
            {isOutsideTradingHours(date) && (
              <SGTooltip title="Extended Trading Hours">
                <sub style={{ fontSize: 8 }}>ETH</sub>
              </SGTooltip>
            )}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Time
        </Typography>
      ),
    },
    {
      headerName: 'Symbol',
      field: OptionsFeedColumnKey.Sym,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Sym),
      minWidth: 110,
      type: 'string',
      getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
      valueFormatter: (value: string) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography>
            {valOrNa(params.value)}{' '}
            {earningsList.find((e) => e.sym === params.value) ? (
              <SGTooltip title="Earnings within 3 weeks from now">
                <sup style={{ fontSize: 8, color: red[600] }}>E</sup>
              </SGTooltip>
            ) : (
              ''
            )}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Symbol
        </Typography>
      ),
    },
    {
      headerName: 'Side',
      field: OptionsFeedColumnKey.Side,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Side),
      minWidth: 90,
      type: 'singleSelect',
      valueOptions: [
        OptionTradeSide.BB,
        OptionTradeSide.B,
        OptionTradeSide.M,
        OptionTradeSide.A,
        OptionTradeSide.AA,
      ],
      getOptionLabel(value: OptionTradeSide) {
        return OptionTradeSideLabels[value];
      },
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: string) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(
              OptionTradeSideLabels[
                params.value as OptionTradeSide
              ]?.toUpperCase(),
            )}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Side
        </Typography>
      ),
    },
    {
      headerName: 'Buy/Sell',
      field: OptionsFeedColumnKey.Aggressor,
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.Aggressor,
      ),
      headerClassName: 'grid-header-cell',
      minWidth: 70,
      type: 'singleSelect',
      valueOptions: [Aggressor.BUY, Aggressor.SELL],
      getOptionLabel(value: Aggressor) {
        return AggressorLabels[value];
      },
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: Aggressor) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        const sentiment: TransactionSentiment = getTransactionSentiment(
          params.row,
        );
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
              color:
                sentiment === 'bullish'
                  ? serverPositiveTrendColor
                  : sentiment === 'bearish'
                  ? serverNegativeTrendColor
                  : 'inherit',
              fontWeight: 600,
            }}
          >
            {valOrNa(params.value.toString().toUpperCase())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Buy/Sell
        </Typography>
      ),
    },
    {
      headerName: 'C/P',
      field: OptionsFeedColumnKey.CP,
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.CP),
      headerClassName: 'grid-header-cell',
      minWidth: 70,
      type: 'singleSelect',
      valueOptions: [OptionType.CALL, OptionType.PUT],
      getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
      valueFormatter: (value: OptionType) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        const sentiment: TransactionSentiment = getTransactionSentiment(
          params.row,
        );

        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
              color:
                sentiment === 'bullish'
                  ? serverPositiveTrendColor
                  : sentiment === 'bearish'
                  ? serverNegativeTrendColor
                  : 'inherit',
              fontWeight: 600,
            }}
          >
            {valOrNa(
              OptionTypeLabels[params.value as OptionType]?.toUpperCase(),
            )}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          C/P
        </Typography>
      ),
    },
    {
      headerName: 'Strike',
      field: OptionsFeedColumnKey.Strike,
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Strike),
      headerClassName: 'grid-header-cell',
      minWidth: 100,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: number) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Strike
        </Typography>
      ),
    },

    {
      headerName: 'Expiration',
      field: OptionsFeedColumnKey.Expiration,
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.Expiration,
      ),
      headerClassName: 'grid-header-cell',
      minWidth: 110,
      type: 'date',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: bigint) => {
        return value ? dayjs.utc(parseInt(value.toString())).toDate() : null;
      },
      valueFormatter: (value: Date) =>
        `${valOrNa(value != null && getDateFormatted(dayjs(value).utc()))}`,
      renderCell: (params: GridRenderCellParams) => {
        const date = dayjs(params.value).utc();
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(date != null && getDateFormatted(date))}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Expiration
        </Typography>
      ),
    },
    {
      headerName: 'Premium',
      field: OptionsFeedColumnKey.Premium,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.Premium,
      ),
      minWidth: 130,
      type: 'number',
      valueFormatter: (value: number) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          sx={{
            ...getBlurredStyles(params),
          }}
        >
          {params.value && `$${formatAsCompactNumber(params.value)}`}
        </Typography>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Premium
        </Typography>
      ),
    },
    {
      headerName: 'Size',
      field: OptionsFeedColumnKey.Size,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Size),
      minWidth: 130,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: OptionType) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Size
        </Typography>
      ),
    },
    {
      headerName: 'Spot',
      field: OptionsFeedColumnKey.StockPrice,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.StockPrice,
      ),
      minWidth: 125,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: OptionType) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {params.value && formatAsCurrency(params.value)}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Spot
        </Typography>
      ),
    },
    {
      headerName: 'Bid',
      field: OptionsFeedColumnKey.Bid,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Bid),
      minWidth: 100,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: OptionType) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Bid
        </Typography>
      ),
    },
    {
      headerName: 'Ask',
      field: OptionsFeedColumnKey.Ask,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Ask),
      minWidth: 100,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: OptionType) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Ask
        </Typography>
      ),
    },
    {
      headerName: 'Option Price',
      field: OptionsFeedColumnKey.Price,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Price),
      minWidth: 130,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: OptionType) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Option Price
        </Typography>
      ),
    },
    {
      headerName: 'Flag',
      field: OptionsFeedColumnKey.SaleType,
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.SaleType,
      ),
      headerClassName: 'grid-header-cell',
      minWidth: 100,
      type: 'singleSelect',
      valueOptions: Object.values(OptionSaleType),
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value ? params.value.toUpperCase() : null)}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Flag
        </Typography>
      ),
    },
    {
      headerName: 'IV',
      field: OptionsFeedColumnKey.IVol,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.IVol),
      minWidth: 80,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: OptionType) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {params.value && formatAsPercentage(params.value)}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          IV
        </Typography>
      ),
    },
  ] as GridColDef[];

  return {
    columns,
  };
};
