import { SelectChangeEvent, Stack, SxProps, Theme } from '@mui/material';
import MinMaxFilter from './MinMaxFilter';
import SliderFilter from './SliderFilter';
import { useRecoilValue } from 'recoil';
import {
  Aggressor,
  Conjunction,
  ConjunctionOp,
  Filter,
  FilterItem,
  FilterOperator,
  FilterValueFormat,
  OptionSaleType,
  OptionsFeedColumnKey,
  OptionTradeSide,
  OptionType,
  OptionTypeLabels,
} from 'types/optionsFeed';
import MultiOptionSelector from './MultiOptionSelector';
import MultiOptionAutocomplete from './MultiOptionAutocomplete';
import {
  OF_DEFAULT_FILTER_ID,
  OF_FILTER_CONJUNCTION_ID,
  OF_SCANNERS,
} from 'config/optionsFeed';
import { hiroSymbolsState, timezoneState, watchlistsState } from 'states';
import OptionsDropdownMultiSelector from './OptionsDropdownSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  addOrUpdateFilters,
  getFilterConjunction,
  getFilterValue,
} from 'util/optionsFeed';
import { Equity, Scanner } from 'types';
import { tnsEquityScannersDataState } from 'states/optionsFeed';
import { getEquitiesForScanner } from 'util/equityhub';
import DateTimeSelector from './DateTimeSelector';
import { dayjs } from 'util/shared';
import ConjunctionFilterSelector from './ConjunctionFilterSelector';

interface FilterPanelProps {
  filters: Filter[];
  onChangeFilters: (newFilters: Filter[]) => void;
  noSym?: boolean;
  viewOnly?: boolean;
  sx?: SxProps<Theme>;
}

const MIN_DAYS = 0;
const MAX_DAYS = 1095; // 3 years

const MIN_PREM = 0;
const MAX_PREM = 2_000_000; // $2M

const minMaxFiltersConfig: {
  title: string;
  format: FilterValueFormat;
  minId: OF_DEFAULT_FILTER_ID;
  maxId: OF_DEFAULT_FILTER_ID;
  field: OptionsFeedColumnKey;
}[] = [
  {
    title: 'STRIKE',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinStrike,
    maxId: OF_DEFAULT_FILTER_ID.MaxStrike,
    field: OptionsFeedColumnKey.Strike,
  },
  {
    title: 'VOLUME',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinVol,
    maxId: OF_DEFAULT_FILTER_ID.MaxVol,
    field: OptionsFeedColumnKey.Volume,
  },
  {
    title: 'O/I',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinOI,
    maxId: OF_DEFAULT_FILTER_ID.MaxOI,
    field: OptionsFeedColumnKey.OI,
  },
  {
    title: 'SIZE',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinSize,
    maxId: OF_DEFAULT_FILTER_ID.MaxSize,
    field: OptionsFeedColumnKey.Size,
  },
  {
    title: 'SPOT',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinSpotPrice,
    maxId: OF_DEFAULT_FILTER_ID.MaxSpotPrice,
    field: OptionsFeedColumnKey.StockPrice,
  },
  {
    title: 'PRICE',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinOptPrice,
    maxId: OF_DEFAULT_FILTER_ID.MaxOptPrice,
    field: OptionsFeedColumnKey.Price,
  },
  {
    title: 'DELTA',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinDelta,
    maxId: OF_DEFAULT_FILTER_ID.MaxDelta,
    field: OptionsFeedColumnKey.Delta,
  },
  {
    title: 'GAMMA',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinGamma,
    maxId: OF_DEFAULT_FILTER_ID.MaxGamma,
    field: OptionsFeedColumnKey.Gamma,
  },
  {
    title: 'VEGA',
    format: 'number',
    minId: OF_DEFAULT_FILTER_ID.MinVega,
    maxId: OF_DEFAULT_FILTER_ID.MaxVega,
    field: OptionsFeedColumnKey.Vega,
  },
  {
    title: 'IV',
    format: 'percentage',
    minId: OF_DEFAULT_FILTER_ID.MinIV,
    maxId: OF_DEFAULT_FILTER_ID.MaxIV,
    field: OptionsFeedColumnKey.IVol,
  },
  {
    title: 'BID',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinBid,
    maxId: OF_DEFAULT_FILTER_ID.MaxBid,
    field: OptionsFeedColumnKey.Bid,
  },
  {
    title: 'ASK',
    format: 'currency',
    minId: OF_DEFAULT_FILTER_ID.MinAsk,
    maxId: OF_DEFAULT_FILTER_ID.MaxAsk,
    field: OptionsFeedColumnKey.Ask,
  },
];

const FilterPanel = ({
  filters,
  onChangeFilters,
  noSym,
  viewOnly,
  sx,
}: FilterPanelProps) => {
  const syms = useRecoilValue(hiroSymbolsState);
  const watchlists = useRecoilValue(watchlistsState);
  const eqScanners = useRecoilValue(tnsEquityScannersDataState);
  const userTimezone = useRecoilValue(timezoneState);

  const [currentTime, setCurrentTime] = useState(
    dayjs().tz(userTimezone).valueOf(),
  );

  // Update the "currentTime" every 60 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs().tz(userTimezone).valueOf()); // Update the "current time" as number in user's timezone
    }, 60000); // 60 seconds interval

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [userTimezone]); // Depend on userTimezone to recompute if the user's timezone changes

  const onFilterChange = useCallback(
    (filter: Filter) => {
      const updatedFilters = addOrUpdateFilters(filters, [filter]);
      onChangeFilters(updatedFilters);
    },
    [filters, onChangeFilters],
  );

  const selectedWatchlists = useMemo(() => {
    const syms: string[] | undefined = (
      filters.find((f) => f.id === OF_DEFAULT_FILTER_ID.Symbols) as FilterItem
    )?.value as string[];

    if (syms && watchlists) {
      // a watchlist is considered "selected" if every symbol it contains is already added to the symbols filter
      return watchlists
        ?.filter((w) => w.symbols.every((s) => syms.includes(s)))
        .map((w) => `${w.id}`);
    }

    return [];
  }, [filters, watchlists]);

  const onWatchlistSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedWatchlistIds = event.target.value as string[];

      if (watchlists) {
        // Extract all watchlist syms
        const watchlistSyms: Set<string> = new Set(
          watchlists.flatMap((w) => w.symbols),
        );
        // Extract symbols from all selected watchlists
        const selectedSymbols = new Set(
          watchlists
            .filter((w) => selectedWatchlistIds.includes(`${w.id}`))
            .flatMap((w) => w.symbols),
        );

        // Check if the filter for symbols already exists
        const existingFilter = filters.find(
          (f) => f.id === OF_DEFAULT_FILTER_ID.Symbols,
        ) as FilterItem;

        // Safely handle the existing filter's value (ensure it's an array or use an empty array)
        const existingValue = (existingFilter?.value as string[]) || [];

        // Filter out symbols that are no longer in the selected watchlists
        const updatedSymbols = existingValue.filter(
          (symbol) => selectedSymbols.has(symbol) || !watchlistSyms.has(symbol),
        );

        // Add new symbols from the selected watchlists
        selectedSymbols.forEach((symbol) => updatedSymbols.push(symbol));

        // Create the updated filter array
        const updatedFilters = addOrUpdateFilters(filters, [
          {
            id: OF_DEFAULT_FILTER_ID.Symbols,
            field: OptionsFeedColumnKey.Sym,
            operator: FilterOperator.IsAnyOf,
            value: Array.from(new Set(updatedSymbols)), // Ensure uniqueness
          },
        ]);

        onChangeFilters(updatedFilters);
      }
    },
    [filters, watchlists, onChangeFilters],
  );

  const onSliderFilterChange = useCallback(
    (
      ids: OF_DEFAULT_FILTER_ID[],
      field: OptionsFeedColumnKey,
      newValue: number | number[],
    ) => {
      const filtersToUpdate = Array.isArray(newValue)
        ? [
            {
              id: ids[0],
              field,
              operator: FilterOperator.GreaterThanOrEqual,
              value: newValue[0] as number,
            },
            {
              id: ids[1],
              field,
              operator: FilterOperator.LessThanOrEqual,
              value: newValue[1] as number,
            },
          ]
        : [
            {
              id: ids[0],
              field,
              operator: FilterOperator.GreaterThanOrEqual,
              value: newValue as number,
            },
          ];

      const updatedFilters = addOrUpdateFilters(filters, filtersToUpdate);
      onChangeFilters(updatedFilters);
    },
    [filters, onChangeFilters],
  );

  const selectedScanners = useMemo(() => {
    const syms: string[] | undefined = (
      filters.find((f) => f.id === OF_DEFAULT_FILTER_ID.Symbols) as FilterItem
    )?.value as string[];

    if (syms && eqScanners) {
      // a scanner is considered "selected" if every symbol it contains is already added to the symbols filter
      return OF_SCANNERS.filter((sc) =>
        getEquitiesForScanner(sc.value, eqScanners).every((e: Equity) =>
          syms.includes(e.sym),
        ),
      ).map((sc) => sc.value);
    }

    return [];
  }, [filters, eqScanners]);

  const onScannerSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedScannerIds = event.target.value as Scanner[];

      if (selectedScannerIds) {
        // Get all scanner syms
        const scannerSyms: Set<string> = new Set(
          OF_SCANNERS.flatMap((sc) =>
            getEquitiesForScanner(sc.value, eqScanners),
          ).map((eq) => eq.sym),
        );
        // Get unique symbols for all scanners that are currently selected
        const selectedSymbols = new Set(
          selectedScannerIds
            .flatMap((scannerId) =>
              getEquitiesForScanner(scannerId, eqScanners),
            )
            .map((equity: Equity) => equity.sym),
        );

        // Check if the filter for symbols already exists
        const existingFilter = filters.find(
          (f) => f.id === OF_DEFAULT_FILTER_ID.Symbols,
        ) as FilterItem;

        // Safely handle the existing filter's value (ensure it's an array or use an empty array)
        const existingValue = (existingFilter?.value as string[]) || [];

        // Filter out symbols that are no longer in the selected scanners
        const updatedSymbols = existingValue.filter(
          (symbol) => selectedSymbols.has(symbol) || !scannerSyms.has(symbol),
        );

        // Add new symbols from the selected scanners
        selectedSymbols.forEach((symbol) => updatedSymbols.push(symbol));

        // Create the updated filter array
        const updatedFilters = addOrUpdateFilters(filters, [
          {
            id: OF_DEFAULT_FILTER_ID.Symbols,
            field: OptionsFeedColumnKey.Sym,
            operator: FilterOperator.IsAnyOf,
            value: Array.from(new Set(updatedSymbols)), // Ensure uniqueness
          },
        ]);

        onChangeFilters(updatedFilters);
      }
    },
    [filters, eqScanners, onChangeFilters],
  );

  // Helper function to handle filter changes for min values
  const handleMinChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: OptionsFeedColumnKey,
    minId: OF_DEFAULT_FILTER_ID,
  ) => {
    onChangeFilters(
      addOrUpdateFilters(filters, [
        {
          field,
          value: event.target.value,
          id: minId,
          operator: FilterOperator.GreaterThanOrEqual,
        },
      ]),
    );
  };

  // Helper function to handle filter changes for max values
  const handleMaxChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: OptionsFeedColumnKey,
    maxId: OF_DEFAULT_FILTER_ID,
  ) => {
    onChangeFilters(
      addOrUpdateFilters(filters, [
        {
          field,
          value: event.target.value,
          id: maxId,
          operator: FilterOperator.LessThanOrEqual,
        },
      ]),
    );
  };

  const validateDateTimeChange = (
    newValue: number | null,
    otherValue: number | null,
    type: 'from' | 'to',
  ): boolean => {
    if (newValue === null) {
      return true;
    }

    // If selecting "From", ensure it's before "To"
    if (type === 'from' && otherValue !== null && newValue > otherValue) {
      console.warn("Selected 'From' datetime cannot be after 'To' datetime.");
      return false;
    }

    // If selecting "To", ensure it's after "From"
    if (type === 'to' && otherValue !== null && newValue < otherValue) {
      console.warn("Selected 'To' datetime cannot be before 'From' datetime.");
      return false;
    }

    // Ensure newValue is not in the future
    const currentTime = dayjs().tz(userTimezone).valueOf();
    if (newValue > currentTime) {
      console.warn('Selected datetime cannot be in the future.');
      return false;
    }

    return true; // Valid datetime change
  };

  const handleMinDateTimeChange = (
    field: OptionsFeedColumnKey,
    newValue: number | null,
    id: OF_DEFAULT_FILTER_ID,
  ) => {
    // Validate the new "From" datetime before applying the change
    if (validateDateTimeChange(newValue, toDateTime, 'from')) {
      onChangeFilters(
        addOrUpdateFilters(filters, [
          {
            field,
            value: newValue === null ? '' : newValue,
            id,
            operator: FilterOperator.GreaterThanOrEqual,
          },
        ]),
      );
    }
  };

  const handleMaxDateTimeChange = (
    field: OptionsFeedColumnKey,
    newValue: number | null,
    id: OF_DEFAULT_FILTER_ID,
  ) => {
    // Validate the new "To" datetime before applying the change
    if (validateDateTimeChange(newValue, fromDateTime, 'to')) {
      onChangeFilters(
        addOrUpdateFilters(filters, [
          {
            field,
            value: newValue === null ? '' : newValue,
            id,
            operator: FilterOperator.LessThanOrEqual,
          },
        ]),
      );
    }
  };

  const fromDateTime = getFilterValue<number | null>(
    filters,
    OF_DEFAULT_FILTER_ID.MinDateTime,
    null,
  );

  const toDateTime = getFilterValue<number | null>(
    filters,
    OF_DEFAULT_FILTER_ID.MaxDateTime,
    null,
  );

  const flagsConjunction = getFilterConjunction(
    filters,
    OF_FILTER_CONJUNCTION_ID.SaleType,
    {
      id: OF_FILTER_CONJUNCTION_ID.SaleType,
      op: ConjunctionOp.Or,
      filters: [],
    },
  );

  return (
    <Stack
      sx={{
        gap: 5,
        ...sx,
      }}
    >
      <Stack sx={{ width: '100%', marginTop: '8px', gap: 4 }}>
        <DateTimeSelector
          label="From"
          value={fromDateTime}
          onChange={(newValue: number | null): void =>
            handleMinDateTimeChange(
              OptionsFeedColumnKey.Time,
              newValue,
              OF_DEFAULT_FILTER_ID.MinDateTime,
            )
          }
          // TODO: figure out when is the absolute minimum day where historical data is available for selection
          // min={}
          max={toDateTime ?? currentTime} // Max is "to" dateTime or current time
          disabled={viewOnly}
        />
        <DateTimeSelector
          label="To"
          value={toDateTime}
          onChange={(newValue: number | null): void =>
            handleMaxDateTimeChange(
              OptionsFeedColumnKey.Time,
              newValue,
              OF_DEFAULT_FILTER_ID.MaxDateTime,
            )
          }
          min={fromDateTime ?? undefined} // Min is "from" dateTime
          max={currentTime} // Max is always "right now"
          disabled={viewOnly}
        />
      </Stack>

      {!noSym && (
        <>
          <MultiOptionAutocomplete
            options={[...syms]}
            value={getFilterValue<string[]>(
              filters,
              OF_DEFAULT_FILTER_ID.Symbols,
              [],
            )}
            onChange={(_event: React.SyntheticEvent, value: string[]) =>
              onFilterChange({
                id: OF_DEFAULT_FILTER_ID.Symbols,
                value,
                operator: FilterOperator.IsAnyOf,
                field: OptionsFeedColumnKey.Sym,
              })
            }
            viewOnly={viewOnly}
          />
          <Stack direction="row" gap={2} alignItems="center" width="100%">
            <OptionsDropdownMultiSelector
              label="Watchlist"
              value={selectedWatchlists}
              options={
                watchlists?.map((w) => ({
                  label: w.name,
                  value: `${w.id!}`,
                })) ?? []
              }
              isMultiple
              onChange={onWatchlistSelectChange}
              viewOnly={viewOnly}
            />
            <OptionsDropdownMultiSelector
              label="Scanner"
              value={selectedScanners}
              viewOnly={viewOnly}
              options={OF_SCANNERS}
              isMultiple
              onChange={onScannerSelectChange}
            />
          </Stack>
        </>
      )}
      <SliderFilter
        viewOnly={viewOnly}
        header="EXPIRATION"
        name="expiration"
        value={[
          getFilterValue<number>(
            filters,
            OF_DEFAULT_FILTER_ID.MinExp,
            MIN_DAYS,
          ),
          getFilterValue<number>(
            filters,
            OF_DEFAULT_FILTER_ID.MaxExp,
            MAX_DAYS,
          ),
        ]}
        min={MIN_DAYS}
        max={MAX_DAYS}
        valueFormat="date"
        onChange={(newValue: number | number[]) =>
          onSliderFilterChange(
            [OF_DEFAULT_FILTER_ID.MinExp, OF_DEFAULT_FILTER_ID.MaxExp],
            OptionsFeedColumnKey.Expiration,
            newValue,
          )
        }
      />
      <SliderFilter
        viewOnly={viewOnly}
        header="MIN PREMIUM"
        name="premium"
        value={getFilterValue<number>(
          filters,
          OF_DEFAULT_FILTER_ID.Premium,
          MIN_PREM,
        )}
        min={MIN_PREM}
        max={MAX_PREM}
        valueFormat="currency"
        onChange={(newValue: number | number[]) =>
          onSliderFilterChange(
            [OF_DEFAULT_FILTER_ID.Premium],
            OptionsFeedColumnKey.Premium,
            newValue,
          )
        }
      />
      <MultiOptionSelector
        viewOnly={viewOnly}
        title="SIDE"
        options={Object.values(OptionTradeSide).map((ots) => ({
          label: ots.toUpperCase(),
          value: ots,
        }))}
        selectedOptions={getFilterValue<string[]>(
          filters,
          OF_DEFAULT_FILTER_ID.Side,
          [],
        )}
        onSelect={(selectedOptions: string[] | string | boolean) =>
          onFilterChange({
            id: OF_DEFAULT_FILTER_ID.Side,
            value: selectedOptions as string[],
            operator: FilterOperator.IsAnyOf,
            field: OptionsFeedColumnKey.Side,
          })
        }
      />
      <MultiOptionSelector
        viewOnly={viewOnly}
        singleSelect
        title="AGRESSOR"
        options={[Aggressor.BUY, Aggressor.SELL].map((a) => ({
          label: a,
          value: a,
        }))}
        selectedOptions={getFilterValue<string>(
          filters,
          OF_DEFAULT_FILTER_ID.Aggressor,
          '',
        )}
        onSelect={(selectedOption: string[] | string | boolean) =>
          onFilterChange({
            id: OptionsFeedColumnKey.Aggressor,
            value: selectedOption as string,
            operator: FilterOperator.Equal,
            field: OptionsFeedColumnKey.Aggressor,
          })
        }
      />
      <MultiOptionSelector
        viewOnly={viewOnly}
        singleSelect
        title="CALL/PUT"
        options={Object.values(OptionType).map((ot) => ({
          label: OptionTypeLabels[ot],
          value: ot,
        }))}
        selectedOptions={getFilterValue<string>(
          filters,
          OF_DEFAULT_FILTER_ID.CP,
          '',
        )}
        onSelect={(selectedOption: string[] | string | boolean) =>
          onFilterChange({
            id: OF_DEFAULT_FILTER_ID.CP,
            value: selectedOption as string,
            operator: FilterOperator.Equal,
            field: OptionsFeedColumnKey.CP,
          })
        }
      />
      {minMaxFiltersConfig.map(({ title, format, minId, maxId, field }) => (
        <MinMaxFilter
          key={`${title}-${minId}-${maxId}`}
          viewOnly={viewOnly}
          title={title}
          format={format}
          minValue={getFilterValue<string>(filters, minId, '')}
          maxValue={getFilterValue<string>(filters, maxId, '')}
          minPlaceholder={
            format === 'currency' ? '$0' : format === 'percentage' ? '0%' : '0'
          }
          maxPlaceholder={
            format === 'currency' ? '$∞' : format === 'percentage' ? '∞%' : '∞'
          }
          onChangeMin={(event) => handleMinChange(event, field, minId)}
          onChangeMax={(event) => handleMaxChange(event, field, maxId)}
        />
      ))}
      <ConjunctionFilterSelector
        title="Flags"
        optionSelectors={[
          {
            title: 'BLOCK',
            options: BOOLEAN_OPTS,
            singleSelect: true,
            viewOnly: viewOnly,
            selectedOptions: getFilterValue<boolean | ''>(
              flagsConjunction.filters,
              OF_DEFAULT_FILTER_ID.IsBlock,
              '',
            ),
            onSelect: (selectedOption: string[] | string | boolean) =>
              onFilterChange({
                id: OF_FILTER_CONJUNCTION_ID.SaleType,
                filters: addOrUpdateFilters(flagsConjunction.filters, [
                  {
                    id: OF_DEFAULT_FILTER_ID.IsBlock,
                    value: selectedOption as boolean,
                    operator: FilterOperator.Equal,
                    field: OptionsFeedColumnKey.IsBlock,
                  },
                ]),
                op: ConjunctionOp.Or,
              }),
          },
          {
            title: 'SWEEP',
            options: BOOLEAN_OPTS,
            singleSelect: true,
            viewOnly: viewOnly,
            selectedOptions: getFilterValue<boolean | ''>(
              flagsConjunction.filters,
              OF_DEFAULT_FILTER_ID.IsSweep,
              '',
            ),
            onSelect: (selectedOption: string[] | string | boolean) =>
              onFilterChange({
                id: OF_FILTER_CONJUNCTION_ID.SaleType,
                filters: addOrUpdateFilters(flagsConjunction.filters, [
                  {
                    id: OF_DEFAULT_FILTER_ID.IsSweep,
                    value: selectedOption as boolean,
                    operator: FilterOperator.Equal,
                    field: OptionsFeedColumnKey.IsSweep,
                  },
                ]),
                op: ConjunctionOp.Or,
              }),
          },
          {
            title: 'SPREAD',
            options: BOOLEAN_OPTS,
            singleSelect: true,
            viewOnly: viewOnly,
            selectedOptions: getFilterValue<boolean | ''>(
              flagsConjunction.filters,
              OF_DEFAULT_FILTER_ID.IsSpread,
              '',
            ),
            onSelect: (selectedOption: string[] | string | boolean) =>
              onFilterChange({
                id: OF_FILTER_CONJUNCTION_ID.SaleType,
                filters: addOrUpdateFilters(flagsConjunction.filters, [
                  {
                    id: OF_DEFAULT_FILTER_ID.IsSpread,
                    value: selectedOption as boolean,
                    operator: FilterOperator.Equal,
                    field: OptionsFeedColumnKey.IsSpread,
                  },
                ]),
                op: ConjunctionOp.Or,
              }),
          },
          {
            title: 'CROSS',
            options: BOOLEAN_OPTS,
            singleSelect: true,
            viewOnly: viewOnly,
            selectedOptions: getFilterValue<boolean | ''>(
              flagsConjunction.filters,
              OF_DEFAULT_FILTER_ID.IsCross,
              '',
            ),
            onSelect: (selectedOption: string[] | string | boolean) =>
              onFilterChange({
                id: OF_FILTER_CONJUNCTION_ID.SaleType,
                filters: addOrUpdateFilters(flagsConjunction.filters, [
                  {
                    id: OF_DEFAULT_FILTER_ID.IsCross,
                    value: selectedOption as boolean,
                    operator: FilterOperator.Equal,
                    field: OptionsFeedColumnKey.IsCross,
                  },
                ]),
                op: ConjunctionOp.Or,
              }),
          },
        ]}
      />
    </Stack>
  );
};

const BOOLEAN_OPTS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export default FilterPanel;
