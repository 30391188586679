import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import {
  tnsActiveWatchlistsIdsState,
  tnsColumnOrderState,
  tnsColumnSizesState,
  tnsColumnsVisibilityState,
  tnsNewFilterState,
  tnsFilterPanelContainerActiveTab,
  tnsFilterPanelContainerOpenState,
  tnsActiveCustomFilterState,
  tnsSavedFiltersState,
  tnsFlowLiveState,
  tnsColumnsSortModelState,
} from 'states/optionsFeed';
import OptionsFeed from './OptionsFeed';
import { Stack } from '@mui/material';
import { OptionsFeedColumnKey } from 'types/optionsFeed';
import DailyFlowOverview from './summary/DailyFlowOverview';

const OptionsFeedContainer = () => {
  const isMobile = useRecoilValue(isMobileState);

  return (
    <Stack
      sx={{
        height: isMobile ? '85vh' : '100%',
        minHeight: isMobile ? '1000px' : '1150px',
        paddingBottom: isMobile ? '60px' : '20px',
        width: '100%',
        gap: 3,
      }}
    >
      <DailyFlowOverview />
      <OptionsFeed
        tnsFlowLiveState={tnsFlowLiveState}
        filterActiveTabState={tnsFilterPanelContainerActiveTab}
        newFilterItemsState={tnsNewFilterState}
        savedFiltersState={tnsSavedFiltersState}
        activeCustomFilterState={tnsActiveCustomFilterState}
        columnSortModel={tnsColumnsSortModelState}
        disabledColumnFilters={[OptionsFeedColumnKey.Time]}
        filterPanelProps={{
          openState: tnsFilterPanelContainerOpenState,
        }}
        columnVisibilityState={tnsColumnsVisibilityState}
        columnOrderState={tnsColumnOrderState}
        columnSizingState={tnsColumnSizesState}
        activeWatchlistIdsState={tnsActiveWatchlistsIdsState}
      />
    </Stack>
  );
};

export default OptionsFeedContainer;
