import { alpha, Box, Divider, useTheme } from '@mui/material';
import SummaryCardWrapper from './SummaryCardWrapper';
import { useRecoilState } from 'recoil';
import { tnsFlowSummaryExpandedState } from 'states/optionsFeed';
import SGAccordion from 'components/SGAccordion';
import RatioPieChart from './cards/RatioPieChart';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import SharedPieLegend from './cards/SharedPieLegend';

const ChartContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    sx={{
      width: {
        sm: 160,
        md: 180,
        lg: 200,
      },
      height: {
        sm: 120,
        md: 140,
        lg: 160,
      },
    }}
  >
    {children}
  </Box>
);

const DataGridFlowSummary = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useRecoilState(tnsFlowSummaryExpandedState);

  const dividerElement = (
    <Divider
      flexItem
      orientation="vertical"
      sx={{ borderColor: alpha(theme.palette.text.secondary, 0.35) }}
    />
  );

  return (
    <SGAccordion
      hideSummaryWhenExpanded
      title="Flow Summary"
      icon={<PieChartRoundedIcon sx={{ color: theme.palette.primary.main }} />}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      sx={{
        backgroundColor: 'background.default',
      }}
      paperSx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        backgroundColor: 'inherit',
        overflowX: 'auto',
        width: '100%',
      }}
    >
      <SharedPieLegend />
      <SummaryCardWrapper title="VOLUME">
        <ChartContainer>
          <RatioPieChart showTotal useCustomLabels />
        </ChartContainer>
      </SummaryCardWrapper>

      {dividerElement}

      <SummaryCardWrapper title="PREMIUM">
        <ChartContainer>
          <RatioPieChart showTotal useCustomLabels />
        </ChartContainer>
      </SummaryCardWrapper>

      {dividerElement}

      <SummaryCardWrapper title="DELTA">
        <ChartContainer>
          <RatioPieChart showTotal useCustomLabels />
        </ChartContainer>
      </SummaryCardWrapper>

      {dividerElement}

      <SummaryCardWrapper title="GAMMA">
        <ChartContainer>
          <RatioPieChart showTotal useCustomLabels />
        </ChartContainer>
      </SummaryCardWrapper>

      {dividerElement}

      <SummaryCardWrapper title="VEGA">
        <ChartContainer>
          <RatioPieChart showTotal useCustomLabels />
        </ChartContainer>
      </SummaryCardWrapper>
    </SGAccordion>
  );
};

export default DataGridFlowSummary;
