import { alpha, Divider, Stack, Typography, useTheme } from '@mui/material';
import SummaryCardWrapper from './SummaryCardWrapper';
import { useRecoilState } from 'recoil';
import { tnsDailyOverviewExpandedState } from 'states/optionsFeed';
import SGAccordion from 'components/SGAccordion';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import TopNamesList from './cards/TopNamesList';

const DailyFlowOverview = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useRecoilState(tnsDailyOverviewExpandedState);

  const dividerElement = (
    <Divider
      flexItem
      orientation="vertical"
      sx={{ borderColor: alpha(theme.palette.text.secondary, 0.35) }}
    />
  );

  return (
    <SGAccordion
      hideSummaryWhenExpanded
      title="Market Highlights"
      icon={
        <TrendingUpRoundedIcon sx={{ color: theme.palette.primary.main }} />
      }
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      paperSx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        overflowX: 'auto',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <SummaryCardWrapper
          title="Top Volume (first hour)"
          icon={<BarChartIcon sx={{ fontSize: 18, color: 'text.secondary' }} />}
        >
          <TopNamesList
            items={[
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$25,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
            ]}
          />
        </SummaryCardWrapper>
      </Stack>

      {dividerElement}

      <Stack direction="row" sx={{ gap: 4, width: '100%' }}>
        <SummaryCardWrapper
          title="Top Daily Gamma Notional"
          icon={
            <ShowChartIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
          }
        >
          <TopNamesList
            items={[
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$25,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
            ]}
          />
        </SummaryCardWrapper>
      </Stack>

      {dividerElement}

      <Stack direction="row" sx={{ gap: 4, width: '100%' }}>
        <SummaryCardWrapper
          title="Top Daily Movers"
          icon={<SwapVertIcon sx={{ fontSize: 16, color: 'text.secondary' }} />}
        >
          <TopNamesList
            items={[
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$25,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
            ]}
          />
        </SummaryCardWrapper>
      </Stack>

      {dividerElement}
      <Stack direction="row" sx={{ gap: 4, width: '100%' }}>
        <SummaryCardWrapper
          title="Largest Daily Trades"
          icon={
            <MonetizationOnIcon
              sx={{ fontSize: 16, color: 'text.secondary' }}
            />
          }
        >
          <TopNamesList
            items={[
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$25,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
              {
                name: 'TSLA',
                value: '$250,000',
              },
            ]}
          />
        </SummaryCardWrapper>
      </Stack>
    </SGAccordion>
  );
};

export default DailyFlowOverview;
