import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { red, blue, grey } from '@mui/material/colors';

const COLORS = {
  PUT: red[500],
  CALL: blue[500],
  TOTAL: grey[500],
};

const legendItems = [
  { name: 'PUT', color: COLORS.PUT },
  { name: 'CALL', color: COLORS.CALL },
  { name: 'TOTAL', color: COLORS.TOTAL },
];

const SharedPieLegend: React.FC = () => {
  return (
    <Stack sx={{ gap: 3, justifyContent: 'center' }}>
      {legendItems.map((item) => (
        <Stack
          key={item.name}
          sx={{ gap: 1, flexDirection: 'row', alignItems: 'center' }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: item.color,
              borderRadius: '50%',
            }}
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bold', color: 'text.secondary' }}
          >
            {item.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default SharedPieLegend;
