import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { blue, red, grey } from '@mui/material/colors';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const generateRandomValue = () =>
  Math.floor(Math.random() * (1000 - 100 + 1) + 100);

const COLORS = [red[500], blue[500], grey[500]];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize={11}
    >
      {value}
    </text>
  );
};

interface RatioPieChartProps {
  showTotal?: boolean;
  useCustomLabels?: boolean;
}

const RatioPieChart: React.FC<RatioPieChartProps> = ({
  showTotal = false,
  useCustomLabels = false,
}) => {
  const data = useMemo(() => {
    const putValue = generateRandomValue();
    const callValue = generateRandomValue();
    const total = putValue + callValue;
    return [
      { name: 'PUT', value: putValue },
      { name: 'CALL', value: callValue },
      ...(showTotal ? [{ name: 'TOTAL', value: total }] : []),
    ];
  }, [showTotal]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart
          margin={{
            left: 20,
            right: 20,
            bottom: 5,
          }}
        >
          <Pie
            data={data.slice(0, 2)}
            cx="50%"
            cy="50%"
            outerRadius={showTotal ? '45%' : '70%'}
            dataKey="value"
            label={useCustomLabels ? renderCustomizedLabel : undefined}
            labelLine={false}
          >
            {data.slice(0, 2).map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          {showTotal && (
            <Pie
              data={[data[2]]}
              cx="50%"
              cy="50%"
              outerRadius="65%"
              innerRadius="50%"
              dataKey="value"
              label={{
                fontSize: 11,
              }}
              labelLine={true}
            >
              <Cell fill={COLORS[2]} />
            </Pie>
          )}
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default RatioPieChart;
